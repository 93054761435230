// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  whatsappButton: "/whatsapp-widget",
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  google: path(ROOTS_AUTH, "/google"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  orgSelector: path(ROOTS_DASHBOARD, "/org-selector"),
  general: {
    home: path(ROOTS_DASHBOARD, "/home"),
    profile: path(ROOTS_DASHBOARD, "/profile"),
    orgProfile: path(ROOTS_DASHBOARD, "/org-profile"),
  },
  organisation: {
    root: path(ROOTS_DASHBOARD, "/organisation"),
    units: path(ROOTS_DASHBOARD, "/organisation/units"),
    users: {
      root: path(ROOTS_DASHBOARD, "/organisation/users"),
      list: path(ROOTS_DASHBOARD, "/organisation/users/list"),
      create: path(ROOTS_DASHBOARD, "/organisation/users/create-user"),
      profile: path(ROOTS_DASHBOARD, "/users/profile"),
      edit: (name) => path(ROOTS_DASHBOARD, `/organisation/users/${name}/edit`),
    },
    customFields: path(ROOTS_DASHBOARD, "/organisation/custom-fields"),
  },

  socialPost: {
    root: path(ROOTS_DASHBOARD, "/social-post"),
    newPost: path(ROOTS_DASHBOARD, "/social-post/new-post"),
    allPosts: path(ROOTS_DASHBOARD, "/social-post/all-posts"),
    analytics: path(ROOTS_DASHBOARD, "/social-post/analytics"),
    postDetails: (name, id) =>
      path(ROOTS_DASHBOARD, `/social-post/analytics/${name}/${id}`),
    postLinks: path(ROOTS_DASHBOARD, "/social-post/all-posts/post-links"),
    facebookAndInstagram: path(ROOTS_DASHBOARD, "/social-post/settings"),
  },

  chatApp: {
    settings: path(ROOTS_DASHBOARD, "/chat-app-settings"),
    chatReport: path(ROOTS_DASHBOARD, "/chat-app/report"),
    bot: path(ROOTS_DASHBOARD, "/chat-app/bot"),
    createTrigger: path(ROOTS_DASHBOARD, "/chat-app/bot/create-trigger"),
    editTrigger: path(ROOTS_DASHBOARD, "/chat-app/bot/edit-trigger"),
  },

  whatsapp: {
    root: path(ROOTS_DASHBOARD, "/whatsapp"),
    home: path(ROOTS_DASHBOARD, "/whatsapp/home"),
    integration: path(ROOTS_DASHBOARD, "/whatsapp/integration"),
    createWebhook: path(
      ROOTS_DASHBOARD,
      "/whatsapp/integration/create-webhook"
    ),
    editWebhook: path(ROOTS_DASHBOARD, "/whatsapp/integration/edit-webhook"),
    eCommerce: path(ROOTS_DASHBOARD, "/whatsapp/e-commerce"),
    account: path(ROOTS_DASHBOARD, "/whatsapp/account"),
    settings: path(ROOTS_DASHBOARD, "/whatsapp/account/settings"),
    developers: path(ROOTS_DASHBOARD, "/whatsapp/account/developers"),
    widget: path(ROOTS_DASHBOARD, "/whatsapp/widget"),
    createWidget: path(ROOTS_DASHBOARD, "/whatsapp/widget/create-widget"),
    editWidget: path(ROOTS_DASHBOARD, "/whatsapp/widget/edit-widget"),

    generalSettings: path(ROOTS_DASHBOARD, "/whatsapp/account/setup"),
    catalogue: path(ROOTS_DASHBOARD, "/whatsapp/e-commerce/catalogue"),
    orders: path(ROOTS_DASHBOARD, "/whatsapp/e-commerce/orders"),
    paymentsSetup: path(ROOTS_DASHBOARD, "/whatsapp/e-commerce/payments-setup"),
    reportSettings: path(
      ROOTS_DASHBOARD,
      "/whatsapp/account/settings/config-report"
    ),
    profile: path(ROOTS_DASHBOARD, "/whatsapp/account/settings/profile"),
    insightsMsgLimit: path(ROOTS_DASHBOARD, "/whatsapp/insights"),
    unsubscribersList: path(
      ROOTS_DASHBOARD,
      "/whatsapp/account/settings/unsubscribers-list"
    ),
    billing: path(ROOTS_DASHBOARD, "/whatsapp/account/billing"),
    billingProfile: path(
      ROOTS_DASHBOARD,
      "/whatsapp/account/billing/billing-profile"
    ),

    wallet: path(ROOTS_DASHBOARD, "/whatsapp/account/billing/wallet"),
    invoice: path(ROOTS_DASHBOARD, "/whatsapp/account/billing/invoice"),
    paymentMethods: path(
      ROOTS_DASHBOARD,
      "/whatsapp/account/billing/payment-methods"
    ),
    billingSubscription: path(
      ROOTS_DASHBOARD,
      "/whatsapp/account/billing?tb=subs"
    ),
    subscription: path(
      ROOTS_DASHBOARD,
      "/whatsapp/account/billing/subscription"
    ),
    bot: path(ROOTS_DASHBOARD, "/whatsapp/bot"),
    createTrigger: path(ROOTS_DASHBOARD, "/whatsapp/bot/create-trigger"),
    createBotDefaultTrigger: path(
      ROOTS_DASHBOARD,
      "/whatsapp/bot/create-trigger-default"
    ),
    editTrigger: path(ROOTS_DASHBOARD, "/whatsapp/bot/edit-trigger"),
    editBotDefaultTrigger: path(
      ROOTS_DASHBOARD,
      "/whatsapp/bot/edit-trigger-default"
    ),
    teamInbox: path(ROOTS_DASHBOARD, "/whatsapp/team-inbox"),
    generateReport: path(ROOTS_DASHBOARD, "/whatsapp/team-inbox/report"),
    apiLogs: path(ROOTS_DASHBOARD, "/whatsapp/account/developers/api-logs"),
    forwardWebhooks: path(
      ROOTS_DASHBOARD,
      "/whatsapp/account/developers/forward-webhooks"
    ),
    thridpartyConnectors: path(
      ROOTS_DASHBOARD,
      "/whatsapp/account/developers/thrid-party-connectors"
    ),
    CreateThirdPartyConnector: path(
      ROOTS_DASHBOARD,
      "/whatsapp/account/developers/thrid-party-connectors/create-connector"
    ),
    templates: path(ROOTS_DASHBOARD, "/whatsapp/whatsapp-templates"),
    flows: path(ROOTS_DASHBOARD, "/whatsapp/whatsapp-flows"),
    contacts: path(ROOTS_DASHBOARD, "/whatsapp/whatsapp-contacts"),
    createTemplate: path(
      ROOTS_DASHBOARD,
      "/whatsapp/whatsapp-templates/create-template"
    ),
    createFlows: path(ROOTS_DASHBOARD, "/whatsapp/whatsapp-flows/create-flow"),
    createFlowsResponses: path(
      ROOTS_DASHBOARD,
      "/whatsapp/whatsapp-flows/create-responses"
    ),
    createFlowsContent: path(
      ROOTS_DASHBOARD,
      "/whatsapp/whatsapp-flows/create-flow/flow-content"
    ),
    editFlowsContent: path(
      ROOTS_DASHBOARD,
      "/whatsapp/whatsapp-flows/edit-flow/flow-content"
    ),
    broadcast: path(ROOTS_DASHBOARD, "/whatsapp/broadcast"),
    createBroadcast: path(
      ROOTS_DASHBOARD,
      "/whatsapp/broadcast/create-broadcast"
    ),
    broadcastReport: path(
      ROOTS_DASHBOARD,
      "/whatsapp/broadcast/broadcast-report"
    ),
    broadcastLabels: path(
      ROOTS_DASHBOARD,
      "/whatsapp/broadcast/broadcast-labels"
    ),
    creatBroadcastLabel: path(
      ROOTS_DASHBOARD,
      "/whatsapp/broadcast/broadcast-labels/create-label"
    ),
    editBroadcastLabel: path(
      ROOTS_DASHBOARD,
      "/whatsapp/broadcast/broadcast-labels/edit-label"
    ),

    broadcastJourney: {
      list: path(ROOTS_DASHBOARD, "/whatsapp/broadcast/broadcast-journey"),
      createJourney: path(
        ROOTS_DASHBOARD,
        "/whatsapp/broadcast/broadcast-journey/create-journey"
      ),
      newBroadcast: (id, journeyName) =>
        path(
          ROOTS_DASHBOARD,
          `/whatsapp/broadcast/broadcast-journey/new-broadcast/${id}/${journeyName}`
        ),
      editBroadcast: (journeyId, journeyName, broadcastId, broadcastName) =>
        path(
          ROOTS_DASHBOARD,
          `/whatsapp/broadcast/broadcast-journey/broadcast/${journeyId}/${journeyName}/${broadcastId}/${broadcastName}`
        ),
      initiate: (id, name) =>
        path(
          ROOTS_DASHBOARD,
          `/whatsapp/broadcast/broadcast-journey/initiate/${id}/${name}`
        ),
      edit: (id, name) =>
        path(
          ROOTS_DASHBOARD,
          `/whatsapp/broadcast/broadcast-journey/edit/${id}/${name}`
        ),
      history: (id, name) =>
        path(
          ROOTS_DASHBOARD,
          `/whatsapp/broadcast/broadcast-journey/initiate/history/${id}/${name}`
        ),
    },
    dialogflowIntents: path(
      ROOTS_DASHBOARD,
      "/whatsapp/dialogflow/manage-intents"
    ),
    dialogflowAnalytics: path(
      ROOTS_DASHBOARD,
      "/whatsapp/dialogflow/analytics"
    ),
  },

  crm: {
    root: path(ROOTS_DASHBOARD, "/crm"),
    overview: path(ROOTS_DASHBOARD, "/crm/overview"),
  },

  googleMyBusiness: {
    root: path(ROOTS_DASHBOARD, "/google-my-business"),
    reviews: {
      root: path(ROOTS_DASHBOARD, "/google-my-business/reviews"),
      cannedResponses: path(
        ROOTS_DASHBOARD,
        "/google-my-business/reviews/canned-responses"
      ),
      locationReview: (id, name) =>
        path(ROOTS_DASHBOARD, `/google-my-business/reviews/${id}/${name}`),
      locationReviewAnalytics: (id, name) =>
        path(
          ROOTS_DASHBOARD,
          `/google-my-business/reviews/${id}/${name}/analytics`
        ),
    },
    settings: path(ROOTS_DASHBOARD, "/google-my-business/settings"),
    googleMapsApiKeys: path(
      ROOTS_DASHBOARD,
      "/google-my-business/settings/google-maps-api-keys"
    ),
    gmbSeekReviews: path(
      ROOTS_DASHBOARD,
      "/google-my-business/gmb-seek-reviews"
    ),
    locations: path(ROOTS_DASHBOARD, "/google-my-business/locations"),
    addLocation: path(ROOTS_DASHBOARD, "/google-my-business/add-location"),
    edit: (name) => path(ROOTS_DASHBOARD, `/google-my-business/${name}/edit`),
    geoGridScheduleList: path(ROOTS_DASHBOARD, "/google-my-business/geo-grid"),
    locationReport: path(
      ROOTS_DASHBOARD,
      "/google-my-business/geo-grid/location-report"
    ),
    geoGridNewReportSchedule: path(
      ROOTS_DASHBOARD,
      "/google-my-business/geo-grid/new-report-schedule"
    ),
    geoGridReportDetails: (repId) =>
      path(ROOTS_DASHBOARD, `/google-my-business/geo-grid/${repId}`),
    profileInfo: path(ROOTS_DASHBOARD, "/google-my-business/profile-info"),
    profileUpdateHistory: path(
      ROOTS_DASHBOARD,
      "/google-my-business/profile-info/profile-update-history"
    ),
    addUpdate: path(ROOTS_DASHBOARD, "/google-my-business/add-update"),
    updateHistory: path(
      ROOTS_DASHBOARD,
      "/google-my-business/add-update/update-history"
    ),
    uploadPhotos: path(ROOTS_DASHBOARD, "/google-my-business/upload-photos"),
    uploadHistory: path(
      ROOTS_DASHBOARD,
      "/google-my-business/upload-photos/upload-history"
    ),
    LocationsList: path(
      ROOTS_DASHBOARD,
      "/google-my-business/upload-photos/locations-list"
    ),
    services: path(ROOTS_DASHBOARD, "/google-my-business/services"),
    serviceHistory: path(
      ROOTS_DASHBOARD,
      "/google-my-business/services/update-history"
    ),
    schemaInfo: path(ROOTS_DASHBOARD, "/google-my-business/schema-info"),
    generateSchema: path(
      ROOTS_DASHBOARD,
      `/google-my-business/schema-info/generate-schema`
    ),
    // googleWorkspace: path(
    //   ROOTS_DASHBOARD,
    //   "/google-my-business/google-workspace"
    // ),
    // customerInfo: path(
    //   ROOTS_DASHBOARD,
    //   "/google-my-business/google-workspace/info"
    // ),
    // editCustomerInfo: path(
    //   ROOTS_DASHBOARD,
    //   "/google-my-business/google-workspace/info/edit"
    // ),
  },

  googleAds: {
    root: path(ROOTS_DASHBOARD, "/google-ads"),
    overview: path(ROOTS_DASHBOARD, "/google-ads/overview"),
    settings: path(ROOTS_DASHBOARD, "/google-ads/settings"),
  },

  googleWorkspace: {
    root: path(ROOTS_DASHBOARD, "/google-workspace"),
    customerInfo: path(ROOTS_DASHBOARD, "/google-workspace/info"),
    editCustomerInfo: path(ROOTS_DASHBOARD, "/google-workspace/info/edit"),
  },

  assetsManager: {
    root: path(ROOTS_DASHBOARD, "/assets-manager"),
    domainSetup: path(ROOTS_DASHBOARD, "/assets-manager/domain-setup"),
    tag: path(ROOTS_DASHBOARD, "/assets-manager/url-manager/manage-tags"),
    url: path(ROOTS_DASHBOARD, "/assets-manager/url-manager"),
    media: path(ROOTS_DASHBOARD, "/assets-manager/media-manager"),
  },

  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
};
