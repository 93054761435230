import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Config
import { USER_GLOBAL_HOST_API_KEY } from "src/config";
// @mui
import {
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { PATH_AUTH } from "src/routes/paths";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import VerifyOtpForm from "../VerifyOtpForm";
import Iconify from "../../../components/Iconify";
import ForgotPwdOtpForm from "../ForgotPwdOtpForm";
import ResetPasswordForm from "../ResetPasswordForm";
import { use } from "react";
import SuccessfullyReset from "../SuccessfullyReset";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const url = new URL(window.location.href);
  const hostUrl = url.origin;

  const { login } = useAuth();

  const displayName = localStorage.getItem("displayName");

  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // const defaultValues = {
  //   email: "demo@minimals.cc",
  //   password: "demo1234",
  //   remember: true,
  // };
  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError("afterSubmit", { ...error, message: error.error });
      }
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOtpVerified(false);
    setRequestSent(false);
    setOpen(false);
  };

  const [requestSent, setRequestSent] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [resetpassword, setResetPassword] = useState(false);
  const [otpVerifiedToken, setOtpVerifiedToken] = useState("");

  const handleRequestSent = () => {
    setRequestSent(!requestSent);
  };

  const handleOtpVerified = (token) => {
    setOtpVerifiedToken(token);
    setOtpVerified(!otpVerified);
  };
  const handleRestsetOtp = () => {
    setResetPassword(!resetpassword);
  };

  const handleRegisterRoute = () => {
    navigate(PATH_AUTH.register);
  };

  const handleRedirect = () => {
    window.open(
      `${USER_GLOBAL_HOST_API_KEY}/auth/google?redirectDomain=${hostUrl}/auth/google`,
      "_self"
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="forgot-password-dialog"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="forgot-password-dialog">
          {"Forgot password?"}
        </DialogTitle>
        <DialogContent sx={{ pb: 0, mt: 2 }}>
          {!requestSent ? (
            <ForgotPwdOtpForm
              requestStatus={handleRequestSent}
              setUserEmail={setUserEmail}
            />
          ) : !otpVerified ? (
            <VerifyOtpForm
              otpStatus={handleOtpVerified}
              userEmail={userEmail}
            />
          ) : !resetpassword ? (
            <ResetPasswordForm
              token={otpVerifiedToken}
              userEmail={userEmail}
              handleRestsetOtp={handleRestsetOtp}
            />
          ) : (
            <SuccessfullyReset handleClose={handleClose} />
          )}
        </DialogContent>
        <DialogActions>
          {/* {requestSent && !otpVerified && (
            <Button onClick={handleClose}>Back</Button>
          )} */}
          <Button onClick={handleClose}>cancel</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ width: { xs: "100%", sm: "45%" } }}>
        <Typography variant="h2" textAlign="center">{`Sign In`}</Typography>
        <Typography
          variant="subtitle2"
          sx={{ mb: 3, color: "#606060" }}
          textAlign="center"
        >
          {/* Welcome to emovur */}
          {`Welcome to ${displayName}`}
        </Typography>

        <LoadingButton
          // size="small"
          fullWidth
          variant="outlined"
          endIcon={<Iconify icon="flat-color-icons:google" />}
          sx={{
            px: 2,
            py: 1,
            fontWeight: 600,
            borderRadius: "8px",
            color: "primary.main",
            textTransform: "none",
            borderColor: "#d9d9d9",
            "&:hover": {
              backgroundColor: "#f7f7f7",
              borderColor: "#d9d9d9",
            },
          }}
          onClick={handleRedirect}
        >
          Sign In with Google
        </LoadingButton>

        <Typography
          variant="subtitle2"
          sx={{
            mt: 1.5,
            mb: 1.5,
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          or
        </Typography>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            <RHFTextField name="email" label="Email address" size="small" />

            <RHFTextField
              size="small"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <RHFCheckbox name="remember" label="Remember me" />
            <Button onClick={handleClickOpen}>Forgot password?</Button>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ borderRadius: 50 }}
          >
            Login
          </LoadingButton>
          <Typography sx={{ textAlign: "center", mt: 2 }}>
            Don't have an account?{" "}
            <Link
              variant="body2"
              color="primary"
              underline="always"
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={handleRegisterRoute}
            >
              Sign up
            </Link>
          </Typography>
        </FormProvider>
      </Box>
    </>
  );
}
