// utils

import { USER_GLOBAL_HOST_API_KEY } from "src/config";
import axiosInstance from "src/utils/axios";

const getPartnerDetailsFromDomain = async (domain) => {
  try {
    const response = await axiosInstance({
      method: "get",
      url: `${USER_GLOBAL_HOST_API_KEY}/no-auth/upp/partner/assets-metadata`,
      params: {
        domain,
      },
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { getPartnerDetailsFromDomain };
