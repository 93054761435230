// utils
import axios from "src/utils/axios";

export default async function getBotTriggers(date_range) {
  try {
    const response = await axios({
      method: "get",
      url: `/lms_chat/api/bot/trigger_response`,
      params: { date_range },
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
  }
}
