import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Stack,
  Drawer,
  Typography,
  Avatar,
  Divider,
  Link,
} from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import IconLogo from "../../../components/IconLogo";
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
import CallIcon from "@mui/icons-material/Call";
import UserAccount from "src/assets/images/useraccountbackgroundimg.svg";
//
import navConfig from "./NavConfig";
import CollapseButton from "./CollapseButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { upperCase } from "lodash";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  landingLogo: PropTypes.string,
  emovurIcon: PropTypes.string,
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
  landingLogo,
  emovurIcon,
  currentOrganization,
}) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const { default_acc_manager } = currentOrganization || {};

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 2,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {!isCollapse && <Logo landingLogo={landingLogo} />}
          {isCollapse && <IconLogo landingLogo={emovurIcon} />}

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>
      </Stack>
      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
      <Divider sx={{ my: 1 }} />
      {/* {isCollapse && default_acc_manager !== null && (
        <Stack alignItems={"center"}>
          <Avatar
            alt={default_acc_manager?.full_name}
            src={default_acc_manager?.avatarUrl}
          />
        </Stack>
      )}

      {!isCollapse && default_acc_manager !== null && (
        <Box sx={{ m: 1 }}>
          <img src={UserAccount} alt="userAccount" />
          <Box
            sx={{
              mt: "15%",
              position: "relative",
              left: "46%",
              bottom: "37%",
              zIndex: 9,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "800",
                width: isCollapse ? 0 : "100%",
                opacity: isCollapse ? "0" : "1",
                transition:
                  "width 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              }}
            >
              {upperCase(default_acc_manager?.full_name)}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              sx={{
                width: isCollapse ? 0 : "100%",
                opacity: isCollapse ? "0" : "1",
                transition:
                  "width 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              }}
            >
              Relationship manager
            </Typography>
          </Box>
          <Avatar
            sx={{
              width: 65,
              height: 65,
              zIndex: 9,
              position: "relative",
              left: "10.5%",
              bottom: "45%",
              backgroundColor: "#0c9ffa",
              color: "#fff",
            }}
            alt={default_acc_manager?.full_name}
            src={default_acc_manager?.avatarUrl}
          />
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ position: "relative", bottom: "56%", left: "43%" }}
          >
            <Link
              href={`tel:${default_acc_manager?.contact?.country_code}${default_acc_manager?.contact?.phone}`}
              target="_blank"
            >
              <Box
                sx={{
                  p: 0.5,
                  display: "flex",
                  color: "#2065D1",
                  boxShadow: "0px 0px 5px #f0f0f0",
                  borderRadius: "8px",
                  cursor: "pointer",
                  ":hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <CallIcon sx={{ p: 0.3 }} />
              </Box>
            </Link>
            <Link
              href={`https://wa.me/${default_acc_manager?.contact?.phone}`}
              underline="none"
              target="_blank"
            >
              <Box
                sx={{
                  display: "flex",
                  p: 0.5,
                  boxShadow: "0px 0px 5px #f0f0f0",
                  borderRadius: "8px",
                  alignItems: "center",
                  cursor: "pointer",
                  ":hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <WhatsAppIcon color="success" sx={{ p: 0.2 }} />
                <Typography variant="caption" sx={{ ml: 0.5 }}>
                  WhatsApp
                </Typography>
              </Box>
            </Link>
          </Stack>
        </Box>
      )} */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
