import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  CircularProgress,
} from "@mui/material";
// components
import MenuPopover from "../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../components/animate";
// routes
import { PATH_DASHBOARD, PATH_AUTH } from "../../../routes/paths";
// hooks
import useAuth from "../../../hooks/useAuth";
import OrganizationSwitcher from "./OrganizationSwitcher";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Profile",
    linkTo: PATH_DASHBOARD.general.profile,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
      setIsLoggingOut(false);
    } catch (error) {
      setIsLoggingOut(false);
      console.error(error);
    }
  };

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const userRole = JSON.parse(
    localStorage.getItem("currentOrganization")
  )?.role;

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: (theme) =>
          alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        color: (theme) => theme.palette.primary.main,
        // bgcolor: "primary.lighter",
        // color: "#2065D1",
        m: 0,
      },
      children: `${name?.split(" ")[0][0]}`,
    };
  }

  useEffect(() => {
    if (user) {
      setUserName(user?.full_name);
      setUserEmail(user?.contact?.email);
    }
  }, [user]);

  return (
    <>
      <OrganizationSwitcher />

      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {/* <Avatar
          src="https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg"
          alt="Rayan Moran"
        /> */}
        <Avatar {...stringAvatar(userName)} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {userEmail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        {userRole === "admin" && (
          <>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Stack sx={{ p: 1 }}>
              <MenuItem
                key={"Org Profile"}
                to={PATH_DASHBOARD.general.orgProfile}
                component={RouterLink}
                onClick={handleClose}
              >
                {"Org Profile"}
              </MenuItem>
            </Stack>
          </>
        )}

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {isLoggingOut ? <CircularProgress size="20px" /> : "Logout"}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
