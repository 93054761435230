// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// utils
import axiosInstance from "src/utils/axios";
import { updateUserApi } from "src/services/register/updateUser";
import { createUser } from "src/services/register/createUser";
import { addUserToOrg } from "src/services/register/addUserToOrg";
import { USER_GLOBAL_HOST_API_KEY } from "src/config";

// Initial State
const initialState = {
  users: [],
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { users } = action.payload;
    return {
      ...state,
      users,
    };
  },
  CREATE_USER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      users: [...state.users, user],
    };
  },
  UPDATE_USER: (state, action) => {
    const { user } = action.payload;
    const updated = [...state.users];
    const toUpdate = updated.findIndex((userItem) => userItem._id === user._id);
    updated[toUpdate] = user;
    return {
      ...state,
      users: updated,
    };
  },
  CHANGE_USER_STATUS: (state, action) => {
    const { user } = action.payload;
    const updated = [...state.users];
    const toUpdate = updated.findIndex((userItem) => userItem._id === user._id);
    updated[toUpdate].status = user.status;
    return {
      ...state,
      users: updated,
    };
  },
  MAKE_ADMIN: (state, action) => {
    const { user } = action.payload;
    const updated = [...state.users];
    const toUpdate = updated.findIndex((userItem) => userItem._id === user._id);
    updated[toUpdate].role = user.role;
    return {
      ...state,
      users: updated,
    };
  },
  ADD_USER_NODE: (state, action) => {
    const { userID, nodeID } = action.payload;
    const updated = [...state.users];
    const toUpdate = updated.findIndex(
      (userItem) => userItem.user_id === userID
    );
    updated[toUpdate].node_id = nodeID;
    return {
      ...state,
      users: updated,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const UsersContext = createContext();

// Provider Component
export const UsersProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const orgID = JSON.parse(
        localStorage.getItem("currentOrganization")
      )?._id;
      const controller = new AbortController();
      const response = await axiosInstance({
        method: "get",
        url: `${USER_GLOBAL_HOST_API_KEY}/organizations/${orgID}/user`,
      });
      const users = await response.data;
      // cancel the request
      controller.abort();
      dispatch({
        type: "INITIALIZE",
        payload: {
          users,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          users: [],
        },
      });
    }
  };
  useEffect(() => {
    initialize();
  }, []);

  const createUserHandler = async (userData) => {
    try {
      // creating user
      const userFormData = new FormData();
      userFormData.append("full_name", userData.name);
      userFormData.append("email", userData.email);
      userFormData.append("country_code", userData.country_code);
      userFormData.append("phone", userData.phoneNumber);
      userFormData.append("password", userData.password);

      const MongoUser = await createUser(userFormData);

      // adding user to org
      const obj = {
        user: MongoUser._id,
        partner: localStorage.getItem("partnerId"),
        role: "agent",
      };
      await addUserToOrg(
        obj,
        JSON.parse(localStorage.getItem("currentOrganization"))._id
      );
    } catch (error) {
      throw new Error(error?.message);
    }
  };

  const updateUser = async (userData, userId) => {
    try {
      // update user
      const userFormData = new FormData();
      userFormData.append("full_name", userData.name);
      userFormData.append("email", userData.email);
      userFormData.append("country_code", userData.country_code);
      userFormData.append("phone", userData.phoneNumber);
      userFormData.append("password", userData.password);

      await updateUserApi(userFormData, userId);
    } catch (error) {
      throw new Error(error?.message);
    }
  };

  const addUserToNode = async (userID, nodeID) => {
    try {
      const orgID = JSON.parse(localStorage.getItem("currentOrganization"))._id;
      const resellerID = localStorage.getItem("partnerId");
      await axiosInstance({
        method: "get",
        url: `/lms_user/api/node_users`,
        params: {
          reseller_id: resellerID,
          org_id: orgID,
          user_id: userID,
          node_id: nodeID,
        },
      });
      dispatch({
        type: "ADD_USER_NODE",
        payload: {
          userID,
          nodeID,
        },
      });
    } catch (error) {
      throw new Error(error?.message);
    }
  };

  const changeUserStatus = async (status, userId) => {
    try {
      // update user
      const userFormData = new FormData();
      userFormData.append("status", !status);

      const user = await updateUserApi(userFormData, userId);

      dispatch({
        type: "CHANGE_USER_STATUS",
        payload: {
          user,
        },
      });
    } catch (error) {
      throw new Error(error?.message);
    }
  };

  const makeAdmin = async (userId) => {
    try {
      // updating role of user
      const obj = {
        user: userId,
        partner: localStorage.getItem("partnerId"),
        role: "admin",
      };
      await addUserToOrg(
        obj,
        JSON.parse(localStorage.getItem("currentOrganization"))._id
      );

      // dispatch({
      //   type: "MAKE_ADMIN",
      //   payload: {
      //     user,
      //   },
      // });
    } catch (error) {
      throw new Error(error?.message);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        users: state.users,
        createUserHandler,
        updateUser,
        changeUserStatus,
        addUserToNode,
        makeAdmin,
        initialize,
      }}
    >
      <Outlet />
    </UsersContext.Provider>
  );
};
