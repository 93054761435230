import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

import { countries } from "src/assets/data";

import Iconify from "../Iconify";

// ----------------------------------------------------------------------

export default function RHFAutocomplete({
  name,
  label,
  type,
  helperText,
  placeholder,
  hideNone,
  ...other
}) {
  const { control } = useFormContext();

  const formatCountryValue = (country) => {
    if (!country || !country.label || !country.phone) return "";
    return `${country.label}-+${country.phone}`;
  };

  const formatOnlyCountryCode = (country) => {
    if (!country || !country.phone) return "";
    return `+${country.phone}`;
  };

  const parseCountryValue = (value) => {
    if (!value || !value.includes("-+")) return {};
    const [label, phone] = value.split("-+");
    return { label, phone: phone ? `+${phone}` : "" };
  };

  const parseOnlyCountryCodeValue = (value) => {
    if (!value) return {};
    return countries.find((c) => `+${c.phone}` === value) || {};
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ...fieldProps } = field;
        const parsedValue =
          type === "onlyCountryCode"
            ? parseOnlyCountryCodeValue(value)
            : parseCountryValue(value);

        const selectedCountry = getCountry(parsedValue.label);

        const filteredOptions = hideNone
          ? countries.filter((c) => c.label !== "None")
          : countries;

        return (
          <Autocomplete
            {...fieldProps}
            id={`autocomplete-${name}`}
            options={filteredOptions}
            autoHighlight
            getOptionLabel={(option) => `${option.label} (+${option.phone})`}
            isOptionEqualToValue={(option, value) =>
              option.code === value.code || option.phone === value.phone
            }
            filterOptions={(options, state) => {
              const inputValue = state.inputValue.trim().toLowerCase();

              return options.filter((option) => {
                const countryName = option.label.toLowerCase();
                const countryCode = option.code.toLowerCase();
                const phoneCode = option.phone; // e.g., "91"
                const phoneCodeWithPlus = `+${option.phone}`; // e.g., "+91"

                return (
                  countryName.includes(inputValue) || // Search by country name
                  countryCode.includes(inputValue) || // Search by country code (e.g., "IN")
                  phoneCode.includes(inputValue) || // Search by phone code without "+"
                  phoneCodeWithPlus.includes(inputValue) // Search by phone code with "+"
                );
              });
            }}
            value={selectedCountry.code ? selectedCountry : null}
            onChange={(event, newValue) => {
              const formattedValue =
                type === "onlyCountryCode"
                  ? formatOnlyCountryCode(newValue)
                  : formatCountryValue(newValue);
              onChange(formattedValue);
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.code}>
                <Iconify
                  icon={`circle-flags:${option.code?.toLowerCase()}`}
                  sx={{ mr: 1 }}
                />
                {option.label} ({option.code}) +{option.phone}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                error={!!error}
                helperText={error ? error?.message : helperText}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: selectedCountry.code ? (
                    <InputAdornment position="start">
                      <Iconify
                        icon={`circle-flags:${selectedCountry.code?.toLowerCase()}`}
                        sx={{ mr: -0.5, ml: 0.5 }}
                      />
                    </InputAdornment>
                  ) : null,
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
            {...other}
          />
        );
      }}
    />
  );
}

RHFAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  placeholder: PropTypes.string,
  hideNone: PropTypes.bool,
};

// ----------------------------------------------------------------------

export function getCountry(label) {
  return countries.find((c) => c.label === label) || {};
}
