import jwtDecode from "jwt-decode";
// routes
import { PATH_AUTH } from "../routes/paths";
//
import axios from "./axios";
import refreshToken from "./refreshToken";

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  // if (!accessToken) {
  //   return false;
  // }
  // const decoded = jwtDecode(accessToken);

  // const currentTime = Date.now() / 1000;

  // return decoded.exp > currentTime;
  const xUserId = localStorage.getItem("_id");
  if (xUserId) {
    return true;
  }
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(async () => {
    // eslint-disable-next-line no-alert
    try {
      await refreshToken();
    } catch (error) {
      alert("Token expired");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("profile");
      localStorage.removeItem("refreshToken");
      window.location.href = PATH_AUTH.login;
    }
  }, timeLeft);
};

const setSession = (profile, _id) => {
  if (_id) {
    localStorage.setItem("_id", _id);
    profile && localStorage.setItem("currentUser", JSON.stringify(profile));
  } else {
    localStorage.removeItem("_id");
    localStorage.removeItem("currentUser");
  }
};

export { isValidToken, setSession };
